import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Afastamento } from '../models/afastamento';
import { Orgao } from '../models/orgao';
import { Unidade } from '../models/unidade';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
const API = `${environment.urlApi}/orgaos`;

@Injectable({
  providedIn: 'root',
})
export class OrgaoService {
  constructor(private http: HttpClient) { }

  public listarOrgaos() {
    return this.http.get<Orgao[]>(API, httpOptions);
  }

  public inserirOrgao(orgao) {
    return this.http.post<Orgao>(API, orgao, httpOptions);
  }

  public deletarOrgao(orgao) {
    return this.http.delete<Orgao>(`${API}/${orgao.id}`, orgao);
  }

  public editarOrgao(orgao) {
    return this.http.put<Orgao>(`${API}/${orgao.id}`, orgao, httpOptions);
  }

  public buscarOrgao(id) {
    return this.http.get<Orgao>(`${API}/${id}`, httpOptions);
  }

  public buscarUnidadesFilhas(id) {
    return this.http.get<Unidade[]>(`${API}/${id}/unidades`, httpOptions);
  }

  public importarUnidadesDoOrgao(orgao) {
    return this.http.post<Orgao>(
      `${API}/${orgao.id}/unidades/importacao`,
      undefined,
      httpOptions
    );
  }

  public importarPessoasDoOrgao(orgao) {
    return this.http.post<Orgao>(
      `${API}/${orgao.id}/unidades/pessoas/importacao`,
      undefined,
      httpOptions
    );
  }

  public buscarAfastamento(idOrgao): Observable<Afastamento[]> {
    return this.http.get<Afastamento[]>(
      `${API}/${idOrgao}/afastamentos`,
      httpOptions
    );
  }

  public deletarAfastamento(afastamento: Afastamento) {
    return this.http.delete<Afastamento>(
      `${API}/${afastamento.idOrgao}/afastamentos/${afastamento.id}`
    );
  }

  public inserirAfastamento(afastamento: Afastamento) {
    return this.http.post<Afastamento>(
      `${API}/${afastamento.idOrgao}/afastamentos`,
      afastamento,
      httpOptions
    );
  }

  public editarAfastamento(afastamento: Afastamento) {
    return this.http.put<Afastamento>(
      `${API}/${afastamento.idOrgao}/afastamentos/${afastamento.id}`,
      afastamento,
      httpOptions
    );
  }
}
