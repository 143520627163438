<form [formGroup]="form" class="container">
  <input type="hidden" formControlName="id" />

  <dft-multiselect
    [delineado]="false"
    [selecaoUnica]="true"
    [listaDeOpcoes]="listaDeOrgaos"
    formControlName="orgao"
    placeholder="Selecione o Órgão"
    matTooltip="Órgão"
    ordenacao="descricao"
    campoDeTexto="bindName"
    permitirFiltroDePesquisa="true">
  </dft-multiselect>

  <br />

  <mat-form-field appearance="outline">
    <mat-label aria-hidden="true">
      Nome do Vínculo Estratégico
    </mat-label>
    <input aria-label="Nome do Vínculo Estratégico"
      matInput
      name="nome"
      placeholder="Ex.: Lista de Demandas Externas"
      formControlName="nome"
      maxlength="5000"
    />
  </mat-form-field>
  
</form>
