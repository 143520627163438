import { AfterViewInit, ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@dft/security/auth/auth.service';
import { Orgao } from '@dft/shared/models/orgao';
import { OrgaoService } from '@dft/shared/services/orgao.service';
import { tap } from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './objetivo-estrategico-form.component.html',
  styleUrls: ['./objetivo-estrategico-form.component.scss'],
})
export class ObjetivoEstrategicoFormComponent implements AfterViewInit {

  form: FormGroup;
  formValue = null;
  readonly = false;
  isGestorGeral = false;

  listaDeOrgaos: Orgao[];

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private orgaoService: OrgaoService
  ) {
    this.form = this.formBuilder.group({
      id: [null],
      orgao: [null, Validators.required],
      nome: ["", Validators.required],
    });
    this.isGestorGeral = this.authService.isGestorGeral();
  }

  public setFormValue(value: object): void {
    this.formValue = value;
    if (value) {
      this.form.setValue(value);
    }
  }

  public setFormReadOnly(readonly: boolean): void {
    this.readonly = readonly;
  }

  ngAfterViewInit(): void {
    this.obterListaDeOrgaos()
      .pipe(tap(orgaos => {
        this.listaDeOrgaos = Orgao.montarBindName(orgaos);
        this.form.controls['orgao'].setValue(
          this.listaDeOrgaos.find(o => o.id === this.authService.getPessoaLogadaIdOrgao())
        );
      }))
      .subscribe();
  }

  private obterListaDeOrgaos() {
    return this.orgaoService.listarOrgaos();
  }

}
